<script setup lang="ts">
import dayjs from 'dayjs';
import EpiOFConfigModal from '../modal/EpiOFConfigModal.vue';
import { epiServices } from '../../services/epi';
import { useI18n } from 'vue-i18n';
import type { Header } from '~/components/ui/data/DataTable.vue';

// Composables
const { t } = useI18n();
const route = useRoute();
const { getUnitsByMeterId } = useUnits();
const { convertConsumptionToEPI } = useEPI();
const service = epiServices();

// Stores
const appService = appStore();

// Props and emit
defineEmits<{
  (e: 'update'): void;
}>();

// Data
const seletedUnit = ref<EPIType>('kwh');
const configModal = ref<boolean>(false);
const isFabricationOrderModalOpen = ref(false);

// Computed
const areaId = computed(() => {
  return meterStore().getMeter(parseInt(route.params.id as string))?.area.id ?? null;
});
const items = computed(() => {
  return [
    {
      key: 'epi',
      label: 'm³',
    },
  ];
});

const areaIsConfigured = computed(() => {
  if (areaId.value === null) return false;
  const area = areaStore().getArea(areaId.value);
  return area?.production_reporting_frequency && area?.fabrication_order_unit_id;
});

const headers: Header[] = [
  { label: t('global.date'), key: 'start' },
  { label: t('global.hour'), key: 'hour' },
  { label: t('global.duration'), key: 'duration' },
  { label: t('global.OF'), key: 'client_fabrication_order_id' },
  { label: t('global.EPI'), key: 'epi' },
  { label: t('global.product', 2), key: 'fabrication_order_product_names' },
  { label: t('global.quantity'), key: 'quantity' },
  { label: t('analytics.avg_power_in_kW'), key: 'avg_power' },
  { label: t('global.consumption_short'), key: 'consumption' },
];
</script>
<template>
  <ui-card :title="$t('analytics.fabrication_orders')">
    <template #header>
      <ui-button v-if="areaIsConfigured" left-icon-class="!w-4" left-icon="Pencil" color="secondary" @click="configModal = true">
        {{ $t('analytics.update_units') }}
      </ui-button>
      <ui-button
        v-if="areaIsConfigured"
        left-icon-class="!w-4"
        left-icon="Pencil"
        @click="isFabricationOrderModalOpen = !isFabricationOrderModalOpen"
      >
        {{ $t('analytics.update_production') }}
      </ui-button>
    </template>
    <ui-info
      v-if="!areaIsConfigured && !service.loading"
      class="mb-4"
      :message="$t('IPE.no_configure_yet')"
      :link="t('IPE.configure')"
      @click-link="configModal = true"
    />
    <ui-data-table :loading="service.loading" :items="service.fabricationOrdersFiltered" :headers="headers">
      <template #header-epi>
        <ui-dropdown v-model="seletedUnit" size="sm" :items="items" placeholder="IPE" />
      </template>
      <template #item-start="{ item }">
        {{ dayjs(item.start).format('DD-MM-YYYY') }}
      </template>
      <template #item-hour="{ item }">
        {{ dayjs(item.start).format('HH:mm') }}
      </template>
      <template #item-duration="{ item }"> {{ dayjs(item.end).diff(item.start, 'hour') }} h </template>
      <template #item-quantity="{ item }"> {{ item.quantity.toFixed(0) }} {{ getUnitsByMeterId(appService.getMeter).symbol }} </template>
      <template #item-consumption="{ item }">
        <template v-if="item.has_up_to_date_epi"> {{ item.fabrication_order_epis[0].consumption_kwh.toFixed(0) }} kWh </template>
      </template>
      <template #item-avg_power="{ item }">
        <template v-if="item.has_up_to_date_epi">
          {{ (item.fabrication_order_epis[0]?.consumption_kwh.toFixed(0) / dayjs(item.end).diff(item.start, 'hour', true)).toFixed(0) }}
          kW
        </template>
      </template>
      <template #item-epi="{ item }">
        <ui-badge v-if="!item.has_up_to_date_epi" color="orange">Calcule en cours</ui-badge>
        <div v-else>
          {{
            convertConsumptionToEPI({
              value: item.fabrication_order_epis[0].cost_by_quantity,
              meterId: parseInt(route.params.id as string),
              type: seletedUnit,
            })
          }}
          /
          {{ getUnitsByMeterId(parseInt(route.params.id as string)).symbol }}
        </div>
      </template>
      <template #item-fabrication_order_product_names="{ item }">
        <div v-if="item.fabrication_order_products?.length !== 0" class="flex flex-col">
          <div v-for="product in item.fabrication_order_products" :key="product">
            <ui-badge color="blue">{{ product.product.client_product_id }}</ui-badge>
          </div>
        </div>
        <span v-else>--</span>
      </template>
    </ui-data-table>
  </ui-card>

  <AnalyticUpdateFabricationOrderModal
    :open="isFabricationOrderModalOpen"
    @close="isFabricationOrderModalOpen = false"
    @update="service.fetchFabricationOrders()"
  />

  <EpiOFConfigModal v-model="configModal" />
</template>
